let shadowRoot;
window.addEventListener('heyflow-init', (event) => {
    shadowRoot =
    (document.querySelector(`[flow-id="${event.detail.flowID}"]`) &&
        document.querySelector(`[flow-id="${event.detail.flowID}"]`)
        .shadowRoot) ||
    document;
    
    shadowRoot.querySelector("#input-37580cce").heyflowOnAutocomplete = async (text, update) => {
        const results = await (await fetch(`http://www.omdbapi.com?apikey=21136a97&s=${text}`)).json();
        console.log(results)
        setTimeout(() => {
            const items = results.Search.map(({
            Title
        }) => ({
            label: Title
        }));
        update(items);
        }
        ,2000)
    }
})